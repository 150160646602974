import { stringify } from "querystring";
import { Kiosk } from "../../../domains/kiosk/aggregates/Kiosk";

export interface IPrintSector {
  id: string;
  name: string;
  printerId: string;
  ownerId: string;
  tablesIds: string[];
  productsIds: string[];
  totemsIds: string[];
  printerName: string;
}

export interface ConfigTotemMeep {
  localId: string;
  localNome: string;
  temSplitFiscal: boolean;
  gerarNfce: boolean;
  usuarioMeepPOSId: string
  deveImprimirNFCe: boolean;
  papelDeParedeUrl: string;
  papelDeParedeId: string;
  papelDeParedeVideoUrl: string;
  aceitaLeitorCodigoBarra: boolean;
  estaImpressaoComprovanteHabilitado: boolean;
  somenteLeitorCodigoBarra: boolean;
  tipoImpressao: number;
  enderecoServidorLocal: string;
  codigoCashless?: string;
  imprimirCategoriaProduto: boolean;
  camposClienteEstabelecimento: string;
  formaPagamentoVoucher: boolean;
  identificarClienteEstabelecimento: boolean;
  usuarioMeepPOSApelido: string;
  comandas: string;
  mustEnableManagementPassword: boolean;
  percentualTaxaServico: number;
  taxaServicoObrigatoria: boolean;
  deveCobrarTaxaServico: boolean;
  // habilitarSenhaPedido: boolean;
  cartaoRotativo: boolean;
  cartaoMeep: boolean;
  configuracaoSistemasGestao: {
    tipoSistema: {
      descricao: string;
      id: number;
    };
    tipoImpressao: {
      descricao: string;
      id: number;
    };
    impressoraRemotaHabilitada?: boolean;
  }[];
  mensagemAposPagamento: string;
  imprimeCartaoRfid: boolean; //TEMPORARY
  ativarPrecoVariavelCodigoBarra: boolean;
  cartaoCredito: boolean
  cartaoDebito: boolean
  aceitaPIX: boolean
  habilitarAgendamento?: boolean
  agendamentoApenasProximoHorario?: boolean
  desativarIdentificacaoCliente: boolean,
  deveUsarPrisma: boolean,
  deveBloquearVendaFaltaEstoque: boolean,
  tipoBaixaEstoque: number,
  deveExecutarPreValidacaoPedido: boolean,
  // desativarIdentificacaoCliente: boolean
  devePerguntarSePedidoEParaViagem: boolean,
  confirmarCPFAoConsumirCashless: boolean,
  trabalhaComMinimoConsumo: boolean,
  tempoExpiracao?: number,
  ehControleCashlessOnline?: boolean,
  printingSectors?: IPrintSector[],
  duplicarImpressaoServidor?: boolean,
  buscarCartaoPorNome?: string,
  estaSenhaForteHabilitado?: boolean,
  enableRecharge?: boolean,
  duplaValidacaoRecargaCashllesOnline?: boolean
}

export const configTotemMeepToKiosk = (configMeep: ConfigTotemMeep): Kiosk => {
  const _kiosk: Kiosk = {
    id: configMeep.usuarioMeepPOSId,
    generateEletronicInvoice: configMeep.gerarNfce,
    mustPrintEletronicInvoice: configMeep.deveImprimirNFCe,
    hasSplitInvoice: configMeep.temSplitFiscal,
    barCodeMode: configMeep.somenteLeitorCodigoBarra,
    enableBarCode: configMeep.aceitaLeitorCodigoBarra,
    localName: configMeep.localNome,
    cashlessCode: configMeep.codigoCashless,
    token: "",
    mustEnableManagementPassword: configMeep.mustEnableManagementPassword,
    enableVariablePricing: configMeep.ativarPrecoVariavelCodigoBarra,
    // enableOrderPassword: configMeep.habilitarSenhaPedido,
    prism: configMeep.deveUsarPrisma,
    localId: configMeep.localId,
    printType: configMeep.tipoImpressao,
    addressLocalServer: configMeep.enderecoServidorLocal,
    printCategoryInProductName: configMeep.imprimirCategoriaProduto,
    wallpaperImageUrl: configMeep.papelDeParedeUrl,
    wallpaperImageId: configMeep.papelDeParedeId,
    customerField:
      configMeep.camposClienteEstabelecimento?.toLocaleLowerCase().split(";").filter(it => it.length) ??
      [],
    customerFieldEnable: configMeep.identificarClienteEstabelecimento,
    customerFieldDisabled: configMeep.desativarIdentificacaoCliente,
    paymentVoucher: configMeep.formaPagamentoVoucher,
    kioskName: configMeep.usuarioMeepPOSApelido,
    orderPad: (JSON.parse(configMeep.comandas) as {
      id: string;
      numero: string;
    }[]).map((item) => ({ id: item.id, number: item.numero })),
    configSymstem: configMeep.configuracaoSistemasGestao.map(
      (configGestao) => ({
        systemType: configGestao.tipoSistema.id,
        printType: configGestao.tipoImpressao.id,
        enableRemotePrint: configGestao.impressoraRemotaHabilitada
      })
    ),
    serviceRate: configMeep.deveCobrarTaxaServico,
    serviceRatePercent: configMeep.percentualTaxaServico,
    messageOnPayment:
      configMeep.mensagemAposPagamento ??
      "Pedido realizado com Sucesso",
    printReceipt: configMeep.estaImpressaoComprovanteHabilitado,
    newPrinter: true,
    paymentCashless: configMeep.cartaoRotativo,
    paymentNominalCashless: configMeep.cartaoMeep,
    weighingMachineMode: true,

    paymentDebit: configMeep.cartaoDebito,
    paymentCredit: configMeep.cartaoCredito,
    paymentPix: configMeep.aceitaPIX,
    enableSchedule: configMeep.habilitarAgendamento,
    onlyNextTime: true,
    enableInstallment: false,
    hideZeroPrice: true,//TODO: Oculta valores zerados
    //configMeep.agendamentoApenasProximoHorario,
    oldPrinter: configMeep.imprimeCartaoRfid,
    verifyInventory: configMeep.deveExecutarPreValidacaoPedido,
    blockOrderIfEmptyInvetory: configMeep.deveBloquearVendaFaltaEstoque,
    inventoryMovementType: configMeep.tipoBaixaEstoque,
    orderToGo: configMeep.devePerguntarSePedidoEParaViagem,
    requestDocumentOnConsumeCashless: configMeep.confirmarCPFAoConsumirCashless,
    newPosPaymentApi: false,
    minimumConsumption: configMeep.trabalhaComMinimoConsumo,
    expirationTime: configMeep.tempoExpiracao,
    onlineCashlessControl: configMeep.ehControleCashlessOnline,
    printingSectors: configMeep?.printingSectors,
    duplicateRemotePrint: configMeep?.duplicarImpressaoServidor,
    searchMeepCardByName: configMeep.buscarCartaoPorNome,
    useStrongPassword: configMeep.estaSenhaForteHabilitado,
    enableRecharge: configMeep.enableRecharge,
    doubleValidationOnlineRecharge: configMeep.duplaValidacaoRecargaCashllesOnline,
  };
  return _kiosk;
};
