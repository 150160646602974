import { Icon, IconButton, Modal } from "@material-ui/core";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ICreateDefferedPromise,
  createDefferedPromise,
} from "utils/DefferedPromise";
import styles from "./ActionModal.module.scss";

let closeDelayTimeout: NodeJS.Timeout;
let confirmationDelayTimeout: NodeJS.Timeout;
let cancelationDelayTimeout: NodeJS.Timeout;

export interface IActionModalHandler {
  open(): Promise<any>;
}

interface ActionModalProps {
  title: string;
  image?: any;
  message: string;
  confirmTitle: string;
  onClickConfirm?: () => void;
  hideCloseButton?: boolean;
  closeButtonTile?: string;
  oldStyle?: boolean;
  confirmationDelay?: number;
  cancelationDelay?: number;
  autoCloseDelay?: number;
}

const ActionModal: React.ForwardRefRenderFunction<
  IActionModalHandler,
  ActionModalProps
> = (
  {
    confirmTitle,
    image,
    message,
    onClickConfirm,
    title,
    hideCloseButton,
    closeButtonTile,
    oldStyle,
    confirmationDelay,
    autoCloseDelay,
    cancelationDelay
  },
  ref
) => {
  const deffered = useRef<ICreateDefferedPromise<any>>(createDefferedPromise());
  const [visible, setVisible] = useState(false);
  
  const [confirmationDelayTime, setConfirmationDelayTime] = useState(confirmationDelay ?? 0);
  const [cancelationDelayTime, setCancelationDelayTime] = useState(confirmationDelay ?? 0);
  const [autoCloseDelayTime, setAutoCloseDelayTime] = useState(autoCloseDelay ?? 0);

  useEffect(() => {
    if(visible) {
      setConfirmationDelayTime(confirmationDelay ?? 0);
      setAutoCloseDelayTime(autoCloseDelay ?? 0);
      setCancelationDelayTime(cancelationDelay ?? 0)
    }
  }, [autoCloseDelay, cancelationDelay, confirmationDelay, visible])
  

  useEffect(() => {
    if(visible && autoCloseDelayTime > 0) {
      closeDelayTimeout = setTimeout(() => {
        setAutoCloseDelayTime((prev) => {
          if (prev === 1) {
            setConfirmationDelayTime(confirmationDelay ?? 0)
            setAutoCloseDelayTime(autoCloseDelay ?? 0)
            setVisible(false);
            deffered.current.rejecter(new Error("Autoclose time expired"));
          }
          return prev - 1
        });
      }, 1000);
    }
  
    return () => {
      clearTimeout(closeDelayTimeout);
    }
  }, [autoCloseDelay, autoCloseDelayTime, confirmationDelay, visible])

  useEffect(() => {
    if(confirmationDelayTime > 0) {
      confirmationDelayTimeout = setTimeout(() => {
        setConfirmationDelayTime((prev) => prev - 1);
      }, 1000);
    }
  
    return () => {
      clearTimeout(confirmationDelayTimeout);
    }
  }, [confirmationDelayTime])
  

  useEffect(() => {
    if(cancelationDelayTime > 0) {
      cancelationDelayTimeout = setTimeout(() => {
        setCancelationDelayTime((prev) => prev - 1);
      }, 1000);
    }
  
    return () => {
      clearTimeout(cancelationDelayTimeout);
    }
  }, [cancelationDelayTime])
  

  const open = useCallback(() => {
    deffered.current = createDefferedPromise<any>();
    setVisible(true);
    return deffered.current.promise;
  }, []);

  useImperativeHandle(ref, () => ({ open }));

  const confirm = useCallback(() => {
    onClickConfirm && onClickConfirm();
    setVisible(false);
    deffered.current.resolver({});
  }, [onClickConfirm]);

  const close = useCallback(() => {
    setConfirmationDelayTime(confirmationDelay ?? 0)
    setAutoCloseDelayTime(autoCloseDelay ?? 0)
    setVisible(false);
    deffered.current.rejecter(new Error("Fechado pelo usuário ou tempo esgotado"));
  }, [autoCloseDelay, confirmationDelay]);

  const renderConfirmationDelay = useMemo(() => confirmationDelayTime > 0 ? ` (${confirmationDelayTime})` : '', [confirmationDelayTime])
  const renderCancelationDelay = useMemo(() => cancelationDelayTime > 0 ? ` (${cancelationDelayTime})` : '', [cancelationDelayTime])

  return (
    <Modal open={visible}>
      <div className={styles.actionModalContainer}>
        <div
          className={styles.container}
          style={{ backgroundColor: oldStyle ? "white" : "" }}
        >
          {!hideCloseButton && (
            <div className={styles.close} style={{
              color: oldStyle ? "#c00d1e" : "",
            }}>
              <IconButton 
                onClick={close}
                data-custom-id="action-modal-close-icon"
                data-custom-description={`CLOSE: ${title} - ${message}`}
              >
                <Icon>close</Icon>
              </IconButton>
            </div>
          )}
          <div
            className={styles.title}
            style={{
              color: oldStyle ? "#c00d1e" : "",
            }}
          >
            {title}
          </div>
          {image && (
            <div className={styles.imageContainer}>
              <img className="image" src={image} alt="Imagem" />
            </div>
          )}
          <div
            className={styles.text}
            style={{
              color: oldStyle ? "#5F5F5F" : "",
            }}
          >
            {message}
          </div>
          <button
            className={styles.button}
            disabled={confirmationDelayTime > 0}
            onClick={confirm}
            style={{
              backgroundColor: oldStyle ? "#c00d1e" : "",
              color: oldStyle ? "#fff" : "",
              textTransform: "capitalize",
              opacity: confirmationDelayTime > 0 ? .3 : undefined,
            }}
            data-custom-id="action-modal-confirm-button"
            data-custom-description={`${confirmTitle}: ${title} - ${message}`}
          >
            {confirmTitle + renderConfirmationDelay}
          </button>
          {closeButtonTile && (
            <div
              className={styles.closeButton}
              onClick={close}
              style={{
                color: oldStyle ? "#c00d1e" : "",
                border: oldStyle ? "1px solid #c00d1e" : "",
                textTransform: "capitalize",
                opacity: cancelationDelayTime > 0 ? .3 : undefined,
              }}
              data-custom-id="action-modal-close-button"
              data-custom-description={`${closeButtonTile}: ${title} - ${message}`}
            >
              {closeButtonTile + renderCancelationDelay}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default React.forwardRef(ActionModal);
