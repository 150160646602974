import { useKiosk } from 'application/contexts/kiosk/KioskContext'
import { PrinterView, PrinterViewComponent } from 'components/printerView/PrinterView'
import { PrintType } from 'domains/kiosk/aggregates/PrintType'
import { Order } from 'domains/order/agreggates/order/Order'
import { orderToPedidoPOS } from 'Infrastructure/repositories/api/OrderRepository'
import { PedidoPos, ProdutoPedidoPos, ProdutoTipo } from 'Infrastructure/repositories/api/PedidoMeep'
import React, { FC, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { ResumoLayoutPrinter } from '../resumoLayoutPrinter/ResumoLayoutPrinter'
import { TicketLayoutPrinter } from '../ticketLayoutPrinter/TicketLayoutPrinter'
import styles from './PrinterOrder.module.scss'

export interface IPrinterOrderRef {
    printOrder: (order: Order) => Promise<void>
}
export interface IPrinterOrderProps {
    //propertys

}
interface ITicket {
    pedidoPos: PedidoPos,
    produtoPedidoPos: ProdutoPedidoPos,
    index: number,
}
const PrinterOrder = forwardRef<IPrinterOrderRef, IPrinterOrderProps>(({ }, ref) => {
    const { kiosk } = useKiosk();

    // const [pedidoPosToPrint, setpedidoPosToPrint] = useState<PedidoPos | null>(null);
    const [ticketToPrint, setTicketToPrint] = useState<ITicket | null>(null);
    const refTicketPrinterView = useRef<PrinterViewComponent>(null);

    const [pedidoPosToPrint, setpedidoPosToPrint] = useState<PedidoPos | null>(null);
    const refResumoPrinterView = useRef<PrinterViewComponent>(null);

    const printOrder = useCallback(
        async (order: Order) => {
            const pedidoPOs = orderToPedidoPOS(order);
            if (kiosk?.printType === PrintType.Ticket) {
                pedidoPOs.produtosObject.map((produto, index) => produto.tipoProduto !== ProdutoTipo.TaxaServico && setTicketToPrint({ pedidoPos: pedidoPOs, produtoPedidoPos: produto, index: index }));
                // setpedidoPosToPrint(pedidoPOs);

            } else if (kiosk?.printType === PrintType.OrderResume) {
                setpedidoPosToPrint(pedidoPOs);
            }
        },
        [kiosk],
    )

    useImperativeHandle(
        ref,
        () => ({
            printOrder
        }),
        [printOrder],
    )


    useEffect(() => {
        if (pedidoPosToPrint) {
            refResumoPrinterView.current?.print();
        }
    }, [pedidoPosToPrint])



    useEffect(() => {
        if (ticketToPrint) {
            refTicketPrinterView.current?.print();
        }
    }, [ticketToPrint])

    return (
        <PrinterView test>
            {
                ticketToPrint && kiosk &&
                <PrinterView id="ticket" ref={refTicketPrinterView}>
                    <TicketLayoutPrinter kiosk={kiosk} ticketToPrint={ticketToPrint}></TicketLayoutPrinter>
                </PrinterView>
            }
            {
                pedidoPosToPrint && kiosk &&
                <PrinterView id="resumo" ref={refResumoPrinterView}>
                    <ResumoLayoutPrinter kiosk={kiosk} pedidoPos={pedidoPosToPrint} />
                </PrinterView>
            }
        </PrinterView>
    )
})
export default PrinterOrder