import React, { createContext, FC, useContext, useState, RefObject, memo, useCallback, useEffect } from 'react'
import { Store } from '../../../../domains/catalog/aggregates/Store'
import { useParams } from 'react-router-dom'
import { Product } from '../../../../domains/catalog/aggregates/Product'
import { useCatalog } from '../../../../application/contexts/catalog/CatalogContext'
import { useUi } from '../../../../application/contexts/ui/UIContext'

interface IStoreProvide {
    openModal: boolean;
    openDetalhamento: (product: Product, refItem: RefObject<HTMLDivElement>, width?: string, height?: string) => void,
    closeDetalhamento: () => void,
    refItem?: RefObject<HTMLDivElement>,
    productSelected?: Product,
    store?: Store;
    featureds?: Product[];
}

const storeContext = createContext<IStoreProvide>({} as IStoreProvide)

export const StoreProvider: FC = memo(({ children }) => {

    const [modalValues, setmodalValues] = useState<{ open: boolean, ref?: RefObject<HTMLDivElement>, product?: Product }>({ open: false })
    const [featureds, setFeatured] = useState<Product[]>();

    const { storeId } = useParams<{ storeId: string }>();

    const { catalog } = useCatalog();

    const { showLoading, hideLoading } = useUi()

    const obterProdutos = useCallback(async () => {
        // showLoading()
        if (storeId) {
            console.log("Obter Produtos from storage");
            // setstore(catalog?.stores.find(_store => (_store.id === storeId)));
        }
    }, [showLoading, storeId, catalog]);

    useEffect(() => {
        obterProdutos();

    }, [obterProdutos])

    useEffect(() => {
        const destaques = catalog?.stores[parseInt(storeId)]?.seccionedProduct.flatMap((sectionedproduct) => sectionedproduct.products).filter((_produto => _produto.isFeatured));
        console.log("destaques", destaques)
        setFeatured(destaques ?? [])

        return () => {

        }
    }, [catalog, storeId])
    
    const openDetalhamento = useCallback((_product: Product, _refItem: RefObject<HTMLDivElement>) => {
        setmodalValues({ open: true, product: _product, ref: _refItem })
    }, [])

    const closeDetalhamento = useCallback(() => {
        setmodalValues(prev => ({ open: false, ref: prev.ref, product: prev.product }))
    }, [])


    // useEffect(() => {
    //     if (!store) {
    //         showLoading()
    //     } else {
    //         hideLoading()
    //     }
    // }, [hideLoading, showLoading, store])
    return (
        <storeContext.Provider value={
            {
                openDetalhamento,
                closeDetalhamento,
                openModal: modalValues.open,
                refItem: modalValues.ref,
                productSelected: modalValues.product,
                store: catalog?.stores[parseInt(storeId)],
                featureds
            }
        }
        >
            {children}
        </storeContext.Provider>
    )
})
export const StoreConsumer = storeContext.Consumer

export const useStore = () => {
    const context = useContext(storeContext)
    return context
}
