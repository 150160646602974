import { Dialog, Icon, IconButton, Modal } from "@material-ui/core";
import { orderToPedidoPOS } from "Infrastructure/repositories/api/OrderRepository";
import { useKiosk } from "application/contexts/kiosk/KioskContext";
import { ErrorCodeType, IError } from "application/models/IError";
import KeyboardPimpad from "areas/payment/keyboardPimpad/KeyboardPimpad";
import { SelectCardType } from "areas/payment/selectCardType/SelectCardType";
import { IEmitedNfce } from "modules/order/domain/models/INfce";
import { IOrder } from "modules/order/domain/models/IOrder";
import { IOrderLog } from "modules/order/domain/models/IOrderLog";
import { IPedidoPosDTO } from "modules/order/domain/models/IPedidoPosDTO";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  ICreateDefferedPromise,
  createDefferedPromise,
} from "utils/DefferedPromise";
import { GetError } from "utils/GetError";
import { Payment } from "../domain/models/IPayment";
import { TransactionType } from "../domain/models/TransactionType";
import { UsePinpad } from "./usePinpad/UsePinpad";
import Pix, { IPixHandler } from "./usePix/Pix";
import UseCashless, { ICashlessHandler } from "./useCashless/UseCashless";
import { ICustomer } from "modules/order/domain/models/ICustomer";
import { SystemType } from "domains/kiosk/aggregates/Kiosk";
import { OrderRepositoryLocalStorage } from "Infrastructure/repositories/localStorage/OrderRepositoryLocalStorage";
import { ICardCashless } from "../domain/models/ICashless";
import UseSchoolCashless, {
  ISchoolCashlessHandler,
} from "./useSchoolCashless/UseSchoolCashless";
import { useAppInsights } from "Infrastructure/repositories/appInsights/AppInsights";

const locaStorageOrderRepository = OrderRepositoryLocalStorage();

export interface IPaymentResult {
  order: IOrder;
  payment: Payment;
  paymentType: TransactionType;
  pedidoPos?: IPedidoPosDTO;
}

interface IPaymentProviderProps {
  rechargePayment?: boolean;
}

export interface IPaymentRef {
  open(
    order: IOrder,
    paymentType?: TransactionType,
    schoolCardData?: ICardCashless
  ): Promise<IPaymentResult>;
}

//TODO: Criar UsePaymentProvider
const PaymentProvider: React.ForwardRefRenderFunction<
  IPaymentRef,
  IPaymentProviderProps
> = ({ rechargePayment }, ref) => {
  const pixRef = useRef<IPixHandler>(null);
  const cashlessRef = useRef<ICashlessHandler>(null);
  const schoolCashlessRef = useRef<ISchoolCashlessHandler>(null);
  const paymentRef = useRef<ICreateDefferedPromise<IPaymentResult>>(
    createDefferedPromise()
  );

  const { kiosk } = useKiosk();
  const { addLog } = useAppInsights();
  const installment = useRef(1);

  const orderToPay = useRef<IOrder | null>(null);
  const [inProgress, setInProgress] = useState(false);
  const [showTypeSelection, setShowTypeSelection] = useState(false);

  const [message, setMessage] = useState<string>("");
  const [keyboardValues, setKeyboardValues] = useState<{
    min: number;
    max: number;
    message: string;
  } | null>(null);

  const clearPinpadValues = useCallback(() => {
    setMessage("");
    setKeyboardValues(null);
  }, []);

  const { doPinpadPayment } = UsePinpad();

  const callPinpad = useCallback(
    async (type: TransactionType) => {
      if (!orderToPay.current) {
        setInProgress(false);
        paymentRef.current.rejecter({
          code: ErrorCodeType.PAYMENT,
          message: "Pedido não encontrado",
          caller: "callPinpad",
          file: "PaymentProvider",
        } as IError);
        return;
      }
      try {
        const response = await doPinpadPayment(
          orderToPay.current.totalValue,
          type
        );
        setInProgress(false);
        clearPinpadValues();
        const order = orderToPay.current;
        orderToPay.current = null;
        paymentRef.current.resolver({
          order: { ...order, payment: response },
          payment: response,
          paymentType: type,
        });
      } catch (error) {
        setInProgress(false);
        clearPinpadValues();
        orderToPay.current = null;
        paymentRef.current.rejecter(
          GetError(
            error,
            "callPinpad",
            "PaymentProvider",
            undefined,
            ErrorCodeType.PAYMENT
          )
        );
      }
    },
    [clearPinpadValues, doPinpadPayment]
  );

  const callPix = useCallback(async () => {
    if (!orderToPay.current) {
      paymentRef.current.rejecter({
        code: ErrorCodeType.PAYMENT,
        message: "Pedido não encontrado",
        caller: "callPix",
        file: "PaymentProvider",
      } as IError);
      return;
    }
    try {
      const pixTransaction = await pixRef.current?.open(orderToPay.current);
      if (pixTransaction) {
        const payment = {
          amount: pixTransaction.value,
          success: true,
          paymentMethod: TransactionType.pix,
          FinancialTransactionModel: {
            TipoAdquirente: 10, // meep
          },
        } as Payment;
        const order = orderToPay.current;
        orderToPay.current = null;
        paymentRef.current.resolver({
          order: { ...order, payment, paymentPix: pixTransaction },
          payment,
          paymentType: TransactionType.pix,
        });
      }
    } catch (error) {
      GetError(
        error,
        "callPix",
        "PaymentProvider",
        undefined,
        ErrorCodeType.PAYMENT
      );
      paymentRef.current.rejecter(error);
    }
  }, []);

  const addOrderLog = useCallback(
    async (
      order: IOrderLog | null,
      message: string,
      pedidoPOS?: IPedidoPosDTO,
      payment?: Payment,
      nfce?: IEmitedNfce[]
    ) => {
      try {
        if (order) {
          let orderLog = { ...order, payment, pedidoPOS, nfce };
          console.log({ orderLog, message });
          addLog("payment", {message, orderLog});
          await locaStorageOrderRepository.add(orderLog, message);
        }
      } catch (error) {
        GetError(
          error,
          "addOrderLog",
          "PaymentProvider",
          "Falha ao adicionar log",
          ErrorCodeType.GENERAL
        );
      }
    },
    []
  );

  const callSchoolCashless = useCallback(
    async (schoolCardData: ICardCashless) => {
      if (!orderToPay.current) {
        paymentRef.current.rejecter({
          code: ErrorCodeType.PAYMENT,
          message: "Pedido não encontrado!",
          caller: "callSchoolCashless",
          file: "PaymentProvider",
        } as IError);
        return;
      }

      const totalValue = orderToPay.current.totalValue;
      const balance = schoolCardData.balance;

      if (
        schoolCardData?.dailyLimit &&
        totalValue > schoolCardData.dailyLimit - schoolCardData.dailyExpense
      ) {
        paymentRef.current.rejecter({
          code: ErrorCodeType.PAYMENT,
          message: "Limite diário excedido",
          caller: "callSchoolCashless",
          file: "PaymentProvider",
        } as IError);
        return;
      }

      if (totalValue <= balance) {
      } else if (schoolCardData.allowsNegativeBalance) {
        const negativeBalanceLimit = schoolCardData.negativeBalanceLimit;
        const maxNegativeBalance = negativeBalanceLimit ?? Number.NEGATIVE_INFINITY;

        if (negativeBalanceLimit === null || negativeBalanceLimit === 0) {
        } else if (totalValue > balance + maxNegativeBalance) {
          paymentRef.current.rejecter({
            code: ErrorCodeType.PAYMENT,
            message: "Saldo insuficiente",
            caller: "callSchoolCashless",
            file: "PaymentProvider",
          } as IError);
          return;
        }
      } else {
        paymentRef.current.rejecter({
          code: ErrorCodeType.PAYMENT,
          message: "Saldo insuficiente",
          caller: "callSchoolCashless",
          file: "PaymentProvider",
        } as IError);
        return;
      }

      try {
        const result = await schoolCashlessRef.current?.open(
          orderToPay.current,
          schoolCardData
        );
        if (result && result.success && result.order) {
          paymentRef.current.resolver({
            order: result.order,
            pedidoPos: result.pedidoPOS,
            paymentType: TransactionType.cashless,
            payment: {
              paymentMethod: TransactionType.cashless,
            } as Payment,
          });
        } else {
          paymentRef.current.rejecter(
            GetError(
              new Error("Falha ao pagar com cashless"),
              "callSchoolCashless",
              "PaymentProvider",
              undefined,
              ErrorCodeType.PAYMENT
            )
          );
        }
      } catch (error) {
        GetError(
          error,
          "callSchoolCashless",
          "PaymentProvider",
          undefined,
          ErrorCodeType.PAYMENT
        );
        paymentRef.current.rejecter(error);
      }
    },
    []
  );

  const callCashless = useCallback(async () => {
    if (!orderToPay.current) {
      paymentRef.current.rejecter({
        code: ErrorCodeType.PAYMENT,
        message: "Pedido não encontrado!",
        caller: "callCashless",
        file: "PaymentProvider",
      } as IError);
      return;
    }
    try {
      const result = await cashlessRef.current?.open(orderToPay.current);
      if (result) {
        orderToPay.current = result.order;
        const customer: ICustomer = {
          ...orderToPay.current.customer,
          document:
            orderToPay.current.customer?.document ?? result.cardData.holderDocument,
          name: orderToPay.current.customer?.name ?? result.cardData.holderName,
          associationId: result.cardData.associationId,
          tag: result.cardData.number,
        };

        const isPosPaid = kiosk?.configSymstem.some(
          (it) => it.systemType === SystemType.pospayOrderPad
        );
        const order = orderToPay.current;
        orderToPay.current = null;
        await addOrderLog(
          orderToPay.current,
          `Adicionado desconto de etiqueta tag: ${order.customer?.tag}`,
          orderToPedidoPOS(order)
        );
        paymentRef.current.resolver({
          order: {
            ...order,
            customer,
          },
          paymentType: isPosPaid
            ? TransactionType.pospaid
            : TransactionType.cashless,
          payment: {
            paymentMethod: TransactionType.cashless,
          } as Payment,
        });
      }
    } catch (error) {
      GetError(
        error,
        "callCashless",
        "PaymentProvider",
        undefined,
        ErrorCodeType.PAYMENT
      );
      paymentRef.current.rejecter(error);
    }
  }, [addOrderLog, kiosk]);

  const onSelectType = useCallback(
    async (type: TransactionType, schoolCardData?: ICardCashless) => {
      clearPinpadValues();
      setShowTypeSelection(false);
      await addOrderLog(
        orderToPay.current,
        `Tipo de pagamento: ${TransactionType[type]}`,
        orderToPay.current ? orderToPedidoPOS(orderToPay.current) : undefined
      );
      switch (type) {
        case TransactionType.credit:
          setInProgress(true);
          callPinpad(type);
          break;
        case TransactionType.debit:
          setInProgress(true);
          callPinpad(type);
          break;
        case TransactionType.voucher:
          setInProgress(true);
          callPinpad(type);
          break;
        case TransactionType.cashless:
          if (schoolCardData) {
            callSchoolCashless(schoolCardData);
          } else {
            callCashless();
          }
          break;
        case TransactionType.pospaid:
          callCashless();
          break;
        case TransactionType.pix:
          callPix();
          break;
        default:
          break;
      }
    },
    [
      addOrderLog,
      callCashless,
      callPinpad,
      callPix,
      callSchoolCashless,
      clearPinpadValues,
    ]
  );

  window.showMessageFromClient = (
    message: string,
    comando: number,
    severit: string
  ) => {
    console.log(comando + " " + severit, message);
    setMessage(
      message
        .replace("SOLICITE A SENHA", "Digite sua senha")
        .replace("Transacao Aprov.", "Aguarde, em processamento...")
    );
  };

  window.showKeyboard = (min: number, max: number, message: string) => {
    console.log("min: ", min);
    console.log("max: ", max);
    console.log("message: ", message);
    setKeyboardValues({ min, max, message });
  };

  const closeSelectionModal = useCallback(() => {
    setShowTypeSelection(false);
    orderToPay.current = null;
    paymentRef.current.rejecter({
      code: ErrorCodeType.PAYMENT,
      message: "Fechado pelo usuário",
      caller: "closeSelectionModal",
      file: "PaymentProvider",
    } as IError);
  }, []);

  const open = useCallback(
    (
      order: IOrder,
      paymentType?: TransactionType,
      schoolCardData?: ICardCashless
    ) => {
      // setOrderToPay(order);
      orderToPay.current = order;
      console.log("openPaymentProvider", {
        message,
        keyboardValues,
        orderToPay: orderToPay.current,
      });
      paymentRef.current = createDefferedPromise<IPaymentResult>();
      if (!paymentType) {
        setShowTypeSelection(true);
      } else {
        onSelectType(paymentType, schoolCardData);
      }
      return paymentRef.current.promise;
    },
    [keyboardValues, message, onSelectType]
  );

  useImperativeHandle(ref, () => ({ open }));

  if (!kiosk) {
    return null;
  }

  return (
    <div>
      <Modal open={showTypeSelection}>
        <div className={"payment"}>
          <div className="contentPayment">
            <div className="topo-contentPayment">CONFIRMAR PEDIDO</div>
            <SelectCardType //TODO: Refazer este cara
              kiosk={kiosk}
              onClose={() => console.log("onClose")}
              open={true}
              total={orderToPay.current?.totalValue ?? 0}
              onSelect={onSelectType}
              rechargePayment={!!rechargePayment}
            ></SelectCardType>
            <IconButton
              onClick={closeSelectionModal}
              style={{
                position: "absolute",
                top: 12,
                right: 12,
                color: "#fff",
              }}
            >
              <Icon fontSize="large">close</Icon>
            </IconButton>
          </div>
        </div>
      </Modal>

      <Modal open={!!keyboardValues}>
        <KeyboardPimpad
          total={orderToPay.current?.totalValue ?? 0}
          enableInstallment={kiosk.enableInstallment}
          keyboardValues={keyboardValues}
          onSendInstallment={(parcela) => {
            installment.current = parcela;
            console.log("UPDATE PARCELA", installment);
          }}
          onSend={() => {
            setKeyboardValues(null);
            setMessage("");
          }}
          onCancel={() => {
            setKeyboardValues(null);
            setMessage("");
          }}
        />
      </Modal>

      <Dialog open={inProgress || message !== ""}>
        <div
          style={{
            fontSize: 32,
            padding: 32,
          }}
        >
          {message.length > 0 ? message : "Aguarde, em processamento..."}
        </div>
      </Dialog>
      <Pix ref={pixRef} />
      <UseCashless ref={cashlessRef} />
      <UseSchoolCashless ref={schoolCashlessRef} />
    </div>
  );
};

export default forwardRef(PaymentProvider);
