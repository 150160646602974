import React, { createContext, FC, useCallback, useContext, useEffect, useRef, useState } from "react";

import { useKiosk } from "../../../application/contexts/kiosk/KioskContext";
import { useUi } from "../../../application/contexts/ui/UIContext";
import { ICardCashless, ICardGroupDiscounts, IFullCardData } from "../../../domains/cashless/aggregates/ICardCashless";
import { IOrderPad } from "../../../domains/cashless/aggregates/IOrderPad";
import { SystemType } from "../../../domains/kiosk/aggregates/Kiosk";
import { CashlessRepository } from "../../../Infrastructure/repositories/api/CashlessRepository";
import { CefSharpCardReader } from "../../../Infrastructure/services/cefSharpService/local/CefSharpCardReader";
import { useAppInsights } from "Infrastructure/repositories/appInsights/AppInsights";
import { useHistory } from "react-router-dom";

interface ICardReaderContext {
  cardData: ICardCashless | null;
  orderPad: IOrderPad | null;
  enableSensor: () => void;
  clearCardData: () => void;
  // onReadSensor: (tag: string) => void;
  offlineCardData: IFullCardData | null;
  // onReadCardData: (cardData: IFullCardData) => void;
  readErrorMessage: string | null;
  outOfDateError: string | null;
  cardDetected: boolean;
  getSchoolCardDataWithName: (name: string) => Promise<void>;
  isSchoolData: React.MutableRefObject<boolean>;
  isOnlineRecharge: React.MutableRefObject<boolean>;
  isPaying: React.MutableRefObject<boolean>;
  groupDiscounts: ICardGroupDiscounts[] | null;
  readedCard: string | null;
  setReadedCard: React.Dispatch<React.SetStateAction<string | null>>;
}
//container
const cashlessRepository = CashlessRepository();
const cefSharpCardReader = CefSharpCardReader();

export const CardReaderContext = createContext<ICardReaderContext>({} as ICardReaderContext);

//TODO: Refazer/remover contexto CardReader
export const CardReaderProvider: FC = ({ children }) => {
  const { kiosk } = useKiosk();
  let history = useHistory();
  const { showLoading, hideLoading, toastFullscreen, toast } = useUi();
  const { addLog } = useAppInsights();
  const [cardData, setCardData] = useState<ICardCashless | null>(null);
  const [groupDiscounts, setGroupDiscounts] = useState<ICardGroupDiscounts[] | null>(null)
  const [offlineCardData, setOfflineCardData] = useState<IFullCardData | null>(null)
  const [orderPad, setOrderPad] = useState<IOrderPad | null>(null);
  const [readErrorMessage, setReadErrorMessage] = useState<string | null>(null);
  const [outOfDateError, setOutOfDateError] = useState<string | null>(null);
  const [cardDetected, setCardDetected] = useState(false);
  const [readedCard, setReadedCard] = useState<string | null>(null)
  
  const isSchoolData = useRef<boolean>(false);
  const isOnlineRecharge = useRef<boolean>(false);
  const isPaying = useRef<boolean>(false);

  const enableSensor = useCallback(async () => {
    if (!kiosk?.cashlessCode) {
      console.log('Falha ao obter codigo de ativação cashless')
      addLog("cardData", {message: 'Falha na ativação cashless'});
    } else {
      try {
        await cefSharpCardReader.startCardReader(kiosk.cashlessCode);
      } catch (error) {
        addLog("cardData", {message: 'Falha na ativação cashless', error});
        setOutOfDateError(String(error));
      }
    }
  }, [addLog, kiosk]);


  const isSystemType = useCallback(
    (systemType: SystemType) => {
      return kiosk?.configSymstem?.find((configSymstem) => configSymstem.systemType === systemType) ? true : false;
    },
    [kiosk]
  );

  const clearCardData = useCallback(() => {
    addLog("cardData", {message: 'Clear card data'});
    console.log("clear data");
    isSchoolData.current = false;
    isOnlineRecharge.current = false;
    isPaying.current = false;
    setReadedCard(null);
    setCardData(null);
    setOfflineCardData(null);
    setOrderPad(null);
    setReadErrorMessage(null);
    setOutOfDateError(null);
  }, [addLog]);

  const getCardData = useCallback(
    async (tag: string) => {
      if (kiosk) {
        showLoading();
        try {
          if (isSystemType(SystemType.pospayOrderPad)) {
            addLog("cardData", {pospayOrderPad: isSystemType(SystemType.pospayOrderPad)});
            const orderpads = await cashlessRepository.getOrdPads(kiosk.localId);
            const orderPad = orderpads.find((_orderPad) => _orderPad.numero.toUpperCase() === tag.toUpperCase());
            console.log("TAG", tag);
            console.log("ORDERPAD", orderPad);
            if (orderPad) {
              setOrderPad(orderPad);
              const responseCardCasheless = await cashlessRepository.getCardData(orderPad.id, orderPad.numero);
              if (responseCardCasheless) {
                if (responseCardCasheless.status === 1) {
                  addLog("cardData", {orderPad, cardData: responseCardCasheless});
                  setCardData(responseCardCasheless);
                  if (responseCardCasheless.group) {
                    try {
                      const result = await cashlessRepository.getGroupData(kiosk.localId, responseCardCasheless.associationId, tag);
                      setGroupDiscounts(result);
                      addLog("cardData", {groupDiscounts: result});
                    } catch (error) {
                      addLog("cardData", {message: 'Falha ao obter grupos de descontos'});
                      setGroupDiscounts(null);
                      console.log('Falha ao obter grupos de descontos', error)
                    }
                  } else {
                    setGroupDiscounts(null);
                  }
                } else {
                  addLog("cardData", {message: 'Cartão/Pulseira bloqueado', tag: responseCardCasheless.tag});
                  toast("Cartão/Pulseira bloqueado", "error");
                }
              } else {
                addLog("cardData", {message: 'Falha ao obter cardData', orderPad});
              }
            } else {
              setCardData(null);
              addLog("cardData", {message: 'Cartão/Pulseira não encontrado', tag});
              toastFullscreen("Cartão/Pulseira não encontrado", "error");
            }
          } else {
            const responseCardCasheless = await cashlessRepository.getCardData(tag, tag);
            if (responseCardCasheless) {
              if (responseCardCasheless.status === 1) {
                addLog("cardData", {orderPad, cardData: responseCardCasheless});
                if (responseCardCasheless.group) {
                  try {
                    const result = await cashlessRepository.getGroupData(kiosk.localId, responseCardCasheless.associationId, tag);
                    setGroupDiscounts(result);
                    addLog("cardData", {groupDiscounts: result});
                  } catch (error) {
                    setGroupDiscounts(null);
                    addLog("cardData", {message: 'Falha ao obter grupos de descontos'});
                    console.log('Falha ao obter grupos de descontos', error)
                  }
                } else {
                  setGroupDiscounts(null);
                }
                setCardData(responseCardCasheless);
              } else {
                toast("Cartão/Pulseira bloqueado", "error");
                addLog("cardData", {message: 'Cartão/Pulseira bloqueado', tag: responseCardCasheless.tag});
              }
            } else {
              addLog("cardData", {message: 'Falha ao obter cardData', orderPad});
            }
          }
        } catch (error) {
          toastFullscreen((error as any)?.response?.data?.message ?? "Cartao/Pulseira não encontrado.", "error");
          addLog("cardData", {message: 'Cartão/Pulseira não encontrado', tag});
        }
        hideLoading();
      }
    },
    [addLog, hideLoading, isSystemType, kiosk, orderPad, showLoading, toast, toastFullscreen]
  );

  const getSchoolCardData = useCallback(
    async (tag: string) => {
      if (kiosk) {
        showLoading();
        try {
          console.log("TAG", tag);
          const responseCardCasheless = await cashlessRepository.getCardData(tag, tag);
          if (responseCardCasheless) {
            if (responseCardCasheless.status === 1) {
              addLog("cardData", {cardData: responseCardCasheless});
              if (responseCardCasheless.group) {
                try {
                  const result = await cashlessRepository.getGroupData(kiosk.localId, responseCardCasheless.associationId, tag);
                  setGroupDiscounts(result);
                  addLog("cardData", {groupDiscounts: result});
                } catch (error) {
                  setGroupDiscounts(null);
                  addLog("cardData", {message: 'Falha ao obter grupos de descontos'});
                  console.log('Falha ao obter grupos de descontos', error)
                } finally {
                  setCardData(responseCardCasheless);
                }
              } else {
                setGroupDiscounts(null);
                setCardData(responseCardCasheless);
              }
            } else {
              addLog("cardData", {message: 'Cartão/Pulseira inativo', tag: responseCardCasheless.tag});
              toast('Cartão/Pulseira inativo', "error");
            }
          }

        } catch (error) {
          addLog("cardData", {message: 'Cartão/Pulseira não encontrado', tag});
          toastFullscreen((error as any)?.response?.data?.message ?? "Cartao não encontrado.", "error");
        }
        hideLoading();
      }
    },
    [addLog, hideLoading, kiosk, showLoading, toast, toastFullscreen]
  );

  const getSchoolCardDataWithName = useCallback(
    async (name: string) => {
      if (kiosk) {
        showLoading();
        try {
          const responseCardCasheless = await cashlessRepository.getSchoolExtractByNickname(name);
          if (responseCardCasheless) {
            if (responseCardCasheless.status === 1) {
              addLog("cardData", {cardData: responseCardCasheless, apelido: name});
              if (responseCardCasheless.group) {
                try {
                  const result = await cashlessRepository.getGroupData(kiosk.localId, responseCardCasheless.associationId, responseCardCasheless.tag);
                  setGroupDiscounts(result);
                  addLog("cardData", {groupDiscounts: result, apelido: name});
                } catch (error) {
                  setGroupDiscounts(null);
                  addLog("cardData", {message: 'Falha ao obter grupos de descontos', apelido: name});
                  console.log('Falha ao obter grupos de descontos', error)
                } finally {
                  setCardData(responseCardCasheless);
                }
              } else {
                setGroupDiscounts(null);
                setCardData(responseCardCasheless);
              }
            } else {
              toast('Cartão inativo', "error");
              addLog("cardData", {message: 'Cartão/Pulseira inativo', tag: responseCardCasheless.tag, apelido: name});
            }
          }

        } catch (error) {
          addLog("cardData", {message: 'Cartão/Pulseira não encontrado', apelido: name});
          toastFullscreen((error as any)?.response?.data?.message ?? "Cartao/Pulseira não encontrado.", "error");
        }
        hideLoading();
      }
    },
    [addLog, hideLoading, kiosk, showLoading, toast, toastFullscreen]
  );

  // Ativação dos serviços de RFID
  useEffect(() => {
    // Habilitar integração de erro de RFID
    const onReadError = (errorMessage: string) => {
      console.log('onReadError', errorMessage);
      setReadErrorMessage(errorMessage)
    }
    window.setReadError = onReadError;

    // Habilitar leitura cashless offline
    const onReadFullCardData = (cardData: IFullCardData) => {
      if (cardData) {
        console.log({ cardData });
        setOfflineCardData(cardData)
      }
    }
    window.setFullCardData = onReadFullCardData;

    // Habilitar leitura de Tag
    const onReadTag = (tag: string) => {
      if (cardData && isOnlineRecharge.current && kiosk?.doubleValidationOnlineRecharge && !isPaying.current) {
        setReadedCard(tag);
      }
      console.log({ tag });
      addLog("cardData", {message: 'onReadTag', tag});
      if (tag && (kiosk?.onlineCashlessControl || isSystemType(SystemType.pospayOrderPad || kiosk?.paymentNominalCashless))) {
        if(kiosk?.paymentNominalCashless) {
          getSchoolCardData(tag);
        } else {
          if (!isPaying.current && !cardData) {
            getCardData(tag);
          }
        }
      }
    }
    window.setCardValue = onReadTag;

    const onRemovedCard = () => {
      console.log('onRemovedCard');
      setCardDetected(false);
    }
    window.onRemovedCard = onRemovedCard;

    const onDetectedCard = () => {
      console.log('onDetectedCard');
      setCardDetected(true);
    }
    window.onDetectedCard = onDetectedCard;


    return () => {
      window.setReadError = () => {
        console.log("DisableReadErro");
      };
      window.setCardValue = () => {
        console.log("DisableReadTag");
      };
      window.setFullCardData = () => {
        console.log("DisableReadCardData");
      };
    };
  }, [addLog, cardData, getCardData, getSchoolCardData, history, isSystemType, kiosk, toast]);


  return (
    <CardReaderContext.Provider
      value={{
        isSchoolData,
        isOnlineRecharge,
        isPaying,
        cardData,
        orderPad,
        enableSensor,
        clearCardData,
        readErrorMessage,
        offlineCardData,
        outOfDateError,
        cardDetected,
        getSchoolCardDataWithName,
        groupDiscounts,
        readedCard,
        setReadedCard
      }}
    >
      {children}
    </CardReaderContext.Provider>
  );
};

export const useCardReader = () => {
  const context = useContext(CardReaderContext);
  return context;
};
