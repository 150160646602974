import { OrderItem } from "./OrderItem";
import { Payment, PixApprovedTransaction } from "../payment/payment";
import { ICustomer } from "../payment/ICustomer";
import { PedidoPos, ProdutoPedidoPos } from "Infrastructure/repositories/api/PedidoMeep";
import { EletronicInvoiceTicket } from "../EletronicInvoiceTicket/EletronicInvoiceTicket";

export interface Order {
  id: string;
  cartDate: string;
  orderItems: OrderItem[];
  totalValue: number;
  payment?: Payment;
  friendlyId: string;
  createdAt: string;
  passwordPanelCode?: string;
  customer?: ICustomer;
  observation?: string;
  paymentPix?: PixApprovedTransaction; 
}

export interface ILog {
  time: string;
  message: string;
}

export interface IPixLog {
  time: string;
  message: string;
  pix: string;
}

export enum OfflineRechargeStatus {
  PAYMENT = 1,
  RECHARGE,
  COMPLETED,
}

export interface OrderLog extends Order {
  log?: ILog[];
  isSynchronized?: boolean;
  pedidoPOS?: PedidoPosOrderLog;
  orderItems: OrderLogItems[];
  offlineRechargeStatus?: OfflineRechargeStatus;
  paymentApproved?: boolean;
  // reprinted?: RePrintOrderSyncObject 
  nfce?: EletronicInvoiceTicket[];
  pixLog?: IPixLog[];
}

export interface ProdutoPedidoPosOrderLog extends ProdutoPedidoPos {
  reprinted?: RePrintOrderSyncObject[];
}


export interface PedidoPosOrderLog extends PedidoPos {
  produtosObject: ProdutoPedidoPosOrderLog[]
  reprinted?: RePrintOrderSyncObject[] 
}






export interface OrderLogItems extends OrderItem {
}

export interface RePrintOrderSyncObject {
  reimpressaoId: string,
  dataRealizacao: Date,
  ticketId: string,
  operadorId: string,
  localClienteId: string,
  equipamentoId: string
}
