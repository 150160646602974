import { Backdrop, Icon } from "@material-ui/core";
import { AlertaMaioridade } from "areas/catalog/components/alertaMaioridade/AlertaMaioridade";
import { ToastProduto } from "areas/catalog/components/carrinho/toastProduto/ToastProduto";
import ModalRetirada, {
  EnumRetirada,
} from "areas/catalog/components/modalRetirada/ModalRetirada";
import { BotaoGrande } from "components/botaoGrande/BotaoGrande";
import { ICartItem } from "modules/cart/domain/models/ICart";
import { IOrder } from "modules/cart/domain/models/IOrder";
import React, { FC, useMemo } from "react";
import UseCartView from "./UseCartView";
import { CartItem } from "./cartItem/CartItem";
import { TransactionType } from "domains/order/agreggates/payment/transactionType";
import "./Carrinho.css";
import { IPaymentResult } from "modules/payment/presentation/PaymentProvider";

//TODO: Usando folha de estilos do Carrinho.css - trazer pra SCSS
export interface ICartViewProps {
  onSendOrder: (
    order: IOrder,
    recharge?: boolean,
    paymentType?: TransactionType
  ) => Promise<IPaymentResult>;
}

const CartView: FC<ICartViewProps> = ({ onSendOrder }) => {
  const {
    lastProductAdded,
    openLastProductToast,
    clearLastProduct,
    cartBarHandle,
    cartItems,
    showCart,
    configCart,
    kiosk,
    totalWithServiceFee,
    clearCart,
    totalServiceFeeValue,
    onConfirmOrder,
    openOrderToGoModal,
    showVerifyMajorityModal,
    onPressSimMaioridade,
    onPressNaoMaioridade,
    sendOder,
    setOpenOrderToGoModal,
    setShowVerifyMajorityModal,
    serviceFeeProduct,
  } = UseCartView(onSendOrder);

  const renderServiceFee = useMemo(() => {
    if (totalServiceFeeValue > 0 && serviceFeeProduct) {
      return (
        <div className="carrinho-item-container">
          <CartItem
            hideZeroPrice={kiosk?.hideZeroPrice}
            orderItem={serviceFeeProduct}
          />
        </div>
      )
    } else {
      return <></>
    }
  }, [kiosk, serviceFeeProduct, totalServiceFeeValue])
  
  

  return (
    <>
      <ToastProduto
        product={lastProductAdded}
        open={openLastProductToast}
        onClose={() => clearLastProduct()}
      />
      <Backdrop
        onClick={cartBarHandle}
        style={{ zIndex: 1 }}
        open={Boolean(cartItems.length && showCart)}
      ></Backdrop>
      <div
        className={
          cartItems.length
            ? showCart
              ? configCart?.fullscreen
                ? "carrinho carrinho-fullscreen carrinho-aberto"
                : "carrinho carrinho-aberto"
              : "carrinho carrinho-fechado"
            : "carrinho carrinho-oculto"
        }
      >
        <div 
          onClick={cartBarHandle} 
          className="barra"
          data-custom-id="cart-bar-button"
          data-custom-description="Abrir/fechar carrinho"
          data-custom-info={JSON.stringify({
            cartItems,
            totalWithServiceFee,
            totalServiceFeeValue,
            serviceFeeProduct,
            showCart
          })}
        >
          {showCart ? (
            !configCart?.disableCloseButton ? (
              <Icon fontSize={"large"}>close</Icon>
            ) : (
              <div />
            )
          ) : (
            <Icon fontSize={"large"}>expand_less</Icon>
          )}
          <div>
            {showCart ? "Carrinho" : configCart?.label ?? "Ver Carrinho"}
          </div>
          <div>
            {/* {totalQuantity}   */}{" "}
            {!(kiosk?.hideZeroPrice && totalWithServiceFee === 0) &&
              " R$" + totalWithServiceFee.toFixed(2).replace(".", ",")}
          </div>
        </div>
        <div className="conteudo">
          <div className="pedidoItensContainer">
            {cartItems.map((orderItem: ICartItem, key: number) => (
              <div key={key} className="carrinho-item-container">
                <CartItem
                  hideZeroPrice={kiosk?.hideZeroPrice}
                  orderItem={orderItem}
                />
              </div>
            ))}
            {renderServiceFee}
            {cartItems && cartItems.length > 0 && (
              <div 
                className="more-itens" 
                onClick={cartBarHandle}
                data-custom-id="cart-add-products-button"
                data-custom-description="Fechar carrinho"
                data-custom-info={JSON.stringify({
                  cartItems,
                  totalWithServiceFee,
                  totalServiceFeeValue,
                  serviceFeeProduct,
                })}
              >
                <Icon fontSize="large">add</Icon>Adicionar mais produtos
              </div>
            )}
          </div>
        </div>
        <div className="rodape">
          <div 
            className="botao-limpar-carrinho" 
            onClick={clearCart}
            data-custom-id="clear-cart-button"
            data-custom-description="Limpar carrinho"
            data-custom-info={JSON.stringify({
              cartItems,
              totalWithServiceFee,
              totalServiceFeeValue,
              serviceFeeProduct,
            })}
          >
            <Icon fontSize="large">close</Icon>Limpar Carrinho
          </div>
          {!(kiosk?.hideZeroPrice && totalWithServiceFee === 0) && (
            <>
              {kiosk?.serviceRate && kiosk.serviceRatePercent && (
                <>
                  <div className="subtotal">
                    <div>Subtotal:</div>
                    <div>
                      R$
                      {(totalWithServiceFee - totalServiceFeeValue)
                        .toFixed(2)
                        .replace(".", ",")}
                    </div>
                  </div>
                  <div className="serviceFeeTotal">
                    <div>
                      Taxa de servico:({kiosk?.serviceRatePercent * 100}%){" "}
                    </div>
                    <div>
                      R${totalServiceFeeValue.toFixed(2).replace(".", ",")}
                    </div>
                  </div>
                </>
              )}
              <div className="total">
                <div>Total:</div>
                <div>R${totalWithServiceFee.toFixed(2).replace(".", ",")}</div>
              </div>
            </>
          )}

          <div className="botoes">
            <BotaoGrande 
              onClick={onConfirmOrder}
              data-custom-id="confirm-cart-button"
              data-custom-description="Confirmar pedido"
              data-custom-info={JSON.stringify({
                cartItems,
                totalWithServiceFee,
                totalServiceFeeValue,
                serviceFeeProduct,
              })}
            >CONFIRMAR PEDIDO</BotaoGrande>
          </div>

          <ModalRetirada
            open={openOrderToGoModal}
            onClick={(value) => {
              sendOder(
                value === EnumRetirada.LEVAR_PARA_VIAGEM
                  ? "PARA VIAGEM"
                  : undefined
              );
              setOpenOrderToGoModal(false);
            }}
            onClose={() => setOpenOrderToGoModal(false)}
          ></ModalRetirada>
        </div>
        <AlertaMaioridade
          open={showVerifyMajorityModal}
          onAllow={onPressSimMaioridade}
          onPressNao={onPressNaoMaioridade}
          onClose={() => {
            setShowVerifyMajorityModal(false);
          }}
        />
      </div>
    </>
  );
};
export default CartView;
