import ActionModal, {
  IActionModalHandler,
} from "components/actionModal/ActionModal";
import PrinterOrder from "components/layoutsPrinter/printerOrder/PrinterOrder";
import CartView from "modules/cart/presentation/cartView/CartView";
import PaymentProvider, {
  IPaymentResult,
} from "modules/payment/presentation/PaymentProvider";
import React, { forwardRef, useRef } from "react";
import { TransactionType } from "../domain/models/INfce";
import { IOrder } from "../domain/models/IOrder";
import UseOrderProvider from "./UseOrderProvider";
import CustomerIdentification, {
  ICustomerIdentificationHandler,
} from "./customerIdentification/CustomerIdentification";

interface IOrderProviderProps {}

export interface IOrderProviderRef {
  startPayment(
    order: IOrder,
    recharge?: boolean,
    paymentType?: TransactionType
  ): Promise<IPaymentResult>;
}

const OrderProvider: React.ForwardRefRenderFunction<
  IOrderProviderRef,
  IOrderProviderProps
> = ({}, ref) => {
  const printReceiptRef = useRef<IActionModalHandler>(null);
  const printNfceRef = useRef<IActionModalHandler>(null);
  const customerRef = useRef<ICustomerIdentificationHandler>(null);

  const {
    startPayment,
    printerOrderComponentRef,
    paymentProvider,
    rechargePayment,
  } = UseOrderProvider(ref, printReceiptRef, printNfceRef, customerRef);

  return (
    <>
      <PaymentProvider
        ref={paymentProvider}
        rechargePayment={rechargePayment}
      />
      <CartView onSendOrder={startPayment} />
      <PrinterOrder ref={printerOrderComponentRef} />
      <ActionModal
        ref={printReceiptRef}
        title=""
        message="Deseja imprimir comprovante de pagamento?"
        confirmTitle="SIM"
        closeButtonTile="NÃO"
        oldStyle
        autoCloseDelay={10}
      />
      <ActionModal
        ref={printNfceRef}
        title=""
        message="Deseja imprimir nota fiscal?"
        confirmTitle="SIM"
        closeButtonTile="NÃO"
        oldStyle
        cancelationDelay={4}
      />
      <CustomerIdentification ref={customerRef} />
    </>
  );
};
export default forwardRef(OrderProvider);
