import { paymentExtractToPayAccountRequest } from "domains/cashless/aggregates/IPaymentAccount";
import { Kiosk } from "domains/kiosk/aggregates/Kiosk";
import { Payment } from "domains/order/agreggates/payment/payment";
import { ICardCashless, ICardGroupDiscounts } from "../../../domains/cashless/aggregates/ICardCashless";
import { IExtract } from "../../../domains/cashless/aggregates/IExtract";
import { IOrderPad } from "../../../domains/cashless/aggregates/IOrderPad";
import { IHttpRepository } from "../../interfaces/IHttpRepository";
import { ExtractRepository } from "./ExtractRepository";
import { HttpRepository } from "./_HttpRepository";
import { DadosConsumoPOS } from "./PedidoMeep";

const extractService = ExtractRepository();

export const CashlessRepository = () => {
  const httpRepository: IHttpRepository = HttpRepository();

  const getCardData = async (id: string, tag: string): Promise<ICardCashless> => {
    return await httpRepository.getAsync<ICardCashless>(`/dispositivo/cartaocashless/${id}/${tag}`);
  };

  const getSchoolExtractByNickname = async (nickname: string): Promise<any> => {
    return await httpRepository.getAsync<any>(`/dispositivo/cartaocashless/apelido/${nickname}`);
  };

  const getGroupData = async (localClienteId: string, historicoAssociacaoId: string, tag: string): Promise<ICardGroupDiscounts[]> => {
    return httpRepository.postAsync<any, ICardGroupDiscounts[]>(`/dispositivo/cartaocashless/buscarDescontosPorCartao`, {localClienteId, historicoAssociacaoId, tag});
  };

  /**
   * Consumacao minima IDs estabelecimentos
   * @param historicoAssociacaoId 
   * @param localId 
   * @param cardId 
   * @returns 
   */
  const getExtractData = async (historicoAssociacaoId: string, localId: string, cardId: string, isPosPaidAndMinConsuption: boolean): Promise<IExtract> => {
    // if (
    //   localId === "a863671b-bfd8-e987-7866-54f32c71be2a" || //Dev
    //   localId === "ab2546d2-18e8-2f0b-e369-b9b17bc43f32" || //Trabuca
    //   localId === "cb74d752-e35e-8384-61ec-1c391782ca63" || //Espaço Augusta Eventos ltda
    //   localId === "023dc92c-2386-9a5a-e01e-28ae3894c189" || //local que samuel pediu 
    //   localId === '02af040e-cff7-408b-dec8-a92cade37f3c' ||
    //   localId === '9a646b11-c414-ddde-ad40-fcf050de5f04' ||
    //   localId === '9fc805e0-60c0-a79a-b2e4-f7561cf34ca3' ||
    //   localId === 'c91e3ec5-8cc5-a668-f401-10ceeeabd266' ||
    //   localId === 'f32674cb-79c5-51ca-aaba-589be1c9b50b' ||
    //   localId === '52d97f4e-5ad8-485f-9a18-85e622837359' ||
    //   localId === 'dbe0e652-06a2-3581-36b0-df2e7b4774f7' ||    //ESPACO IRREGULAR
    //   localId === '38e77ca2-1be5-3cc0-4d89-d9c72e90d9de' ||    //the mexican
    //   localId === 'a63d0758-a968-d0aa-0f07-48069debaa39' ||      //ARENA BADALADINHA PRIME
    //   localId === '6a6a6bc6-5561-d166-3993-04648e423d04'       //testedeivid
    // ) {
    //   //Story bar
    //Cobertura gastrobar
    //Espaço de eventos augusta Ltda
    // Pratifaria bar

    if (isPosPaidAndMinConsuption) {
      const responseParsed = await extractService.getOldExtract(cardId, localId);
      return responseParsed;
      
    } else {
      const responseParsed = await extractService.getSummaryExtract(historicoAssociacaoId, localId);
      return responseParsed;
    }
  };
  const getOrdPads = async (localId: string): Promise<IOrderPad[]> => {
    return await httpRepository.getAsync<IOrderPad[]>(`/dispositivo/${localId}/comandas`);
  };

  const payAccount = async (params: {
    kiosk: Kiosk,
    localId: string,
    orderId: string,
    paymentId: string,
    serviceFeeEnabled: boolean,
    cardData: ICardCashless,
    extract: IExtract,
    orderPad: IOrderPad,
    payment: Payment
  }): Promise<any> => {
    return await httpRepository.postAsync("/CommandEletronic/PayAccount", paymentExtractToPayAccountRequest({ ...params }))
  }

  const doConsumption = async (consumptionOrder: DadosConsumoPOS): Promise<{orderId: string}> => {
    return await httpRepository.postAsync("/CommandEletronic/ConsumeOrder", consumptionOrder)
  }

  return {
    getCardData,
    getExtractData,
    getOrdPads,
    payAccount,
    doConsumption,
    getSchoolExtractByNickname,
    getGroupData
  };
};
