import React, { useState, useCallback, useEffect } from 'react'
import { useAdmin } from '../../application/contexts/admin/adminContext'
import "./Admin.css"
import { Tabs, Tab, Dialog, Container, Button } from '@material-ui/core'
import { OrderLogView } from './orderLog/OrderLogView'
import { AuthOperator } from './authOperator/AuthOperator'
import { BotaoVoltar } from '../../components/botaoVoltar/BotaoVoltar'
import { useHistory } from 'react-router-dom'
import { KioskParameters } from './kioskParameters/KioskParameters'
import ReserveLog from 'modules/tour/presentation/components/reserveLog/ReserveLog'
import { useUi } from 'application/contexts/ui/UIContext'
import { useCatalog } from 'application/contexts/catalog/CatalogContext'
export const Admin = () => {
    const [tabIndex, settabIndex] = useState(0)
    const { isAuthOperator } = useAdmin();
    const history = useHistory();
    const { showLoading} = useUi();
    const {forceSync} = useCatalog();

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        settabIndex(newValue);
    };
    const onClickVotlar = useCallback(
        () => {
            history.goBack()
        },
        [history],
    )

    return (
        <div className="container-admin">
            <div className="top-admin">
                <div className="toolbar-admin">
                    <BotaoVoltar onClick={onClickVotlar}></BotaoVoltar>
                </div>
            </div>
            {
                isAuthOperator ?
                    <Container maxWidth={"sm"} className="content-admin">
                        <div className="tab-admin">
                            <Tabs centered value={tabIndex} onChange={handleChangeTab} aria-label="simple tabs example">
                                <Tab label="Dados Gerais" />
                                {/* <Tab label="Parametros" /> */}
                                <Tab label="Histórico" />
                                <Tab label="Reservas" />
                            </Tabs>
                        </div>{
                            tabIndex === 0 &&
                            <KioskParameters />
                        }{
                            tabIndex === 1 &&
                            <OrderLogView />
                        }
                        {
                            tabIndex === 2 &&
                            <ReserveLog />
                        }
                    </Container>
                    :
                    <AuthOperator></AuthOperator>
            }
            <div className="footer-admin">
                <div className='buttonReload' onClick={forceSync}>Atualizar Dados</div>
            </div>
            {/* <Dialog open={!isAuthOperator} > */}
            {/* </Dialog> */}
        </div >
    )
}
