import React, { FC, useState, useRef, memo, useCallback } from "react";
import "./ProdutoItem.css";
import { Fab, Icon } from "@material-ui/core";
import { } from "react-transition-group";
import { useStore } from "../../../StoreContext";
import LazyLoad from "react-lazyload";
import { Product } from "../../../../../../../domains/catalog/aggregates/Product";
import { useCartStore } from "modules/cart/infra/store/CartStore";
import { ICartContext } from "modules/cart/domain/interfaces/IUseCaseCart";

interface ProdutoItemProps {
  produto: Product;
  tipo?: number;
  hidePrice?: boolean;
}

export const ProdutoItem: FC<ProdutoItemProps> = memo((props) => {
  const [quantidade, setQuantidade] = useState(0);

  // const { addProductCart, removeProductCart, addProductCartVerificarMaioridade } = useCart();
  const [addProductCart, removeProductCart] = useCartStore((state: ICartContext) => [
    state.addProductCart,
    state.removeCartItemByProductId
  ]);
  
  const refContainer = useRef<HTMLDivElement>(null);

  const store = useStore();

  const hasComposition = useCallback((product?: Product) => {
    if (product && product.compositions?.length) {
      if (product.compositions.some(it => it.compositionItems.length > 0)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false
    }
  }, [])

  
  const openDetalhamento = useCallback(() => {
    store.openDetalhamento(props.produto, refContainer);
  },[props.produto, store]);

  const onClickAdd = useCallback(() => {
    if (hasComposition(props.produto) || props.produto?.productGeneralObservation) {
      openDetalhamento()
    } else {
      addProductCart(props.produto, 1, [], props.produto.adultHood);
    }
  },[addProductCart, hasComposition, openDetalhamento, props.produto]);
  
  const onClickRemover = useCallback(() => {
    removeProductCart(props.produto.id, 1);
  },[props.produto, removeProductCart]);


  const onImgError = (ev: React.ChangeEvent<HTMLImageElement>) => {

    console.log("imageError")
    ev.target.src = require("../../../../../../../assets/images/logo-meep.png");

  }

  return (
    <LazyLoad height={200}>
      <div ref={refContainer} className={"produto-item"}>
        {!props.tipo && (
          <img
            className={"image"}
            onErrorCapture={onImgError}
            onClick={openDetalhamento}
            src={props.produto.thumbnailUrl}
            alt="product"
            data-custom-id="item-image-button"
            data-custom-description="Abrir detalhamento"
            data-custom-info={JSON.stringify({
              productId: props.produto.id,
              productName: props.produto.name,
              composition: props.produto.compositions
            })}
          />
        )}
        {props.produto?.discount && (
          <div className="produto-item-desconto">
            -{props.produto.discount}%
          </div>
        )}
        <div
          className={
            quantidade > 0
              ? "quantidade quantidade-visivel"
              : "quantidade quantidade-oculto"
          }
        >
          {quantidade}
        </div>
        <div 
            onClick={openDetalhamento} 
            className={"info"}
            data-custom-id="item-info-button"
            data-custom-description="Abrir detalhamento"
            data-custom-info={JSON.stringify({
              productId: props.produto.id,
              productName: props.produto.name,
              composition: props.produto.compositions
            })}
          >
          <div className={"name"}>{props.produto.name}</div>
          {/* <div className={"description"}>{props.produto.description}</div> */}
          {
            !(!props.hidePrice && props.produto.price === 0) &&
            <div className={"value"}>
              <span 
                className={props.produto.discount ? 'produto-item-desconto-valor' : ''}
              >
                R$ {props.produto.price.toFixed(2).replace(".", ",")}
              </span>
              {props.produto.discount && (
                <span style={{marginLeft: -30}}>
                  &nbsp;R$ {(props.produto.price - (props.produto.price * (props.produto.discount / 100))).toFixed(2).replace(".", ",")}
                </span>
              )}
            </div>
          }
        </div>
        <div className={"botoes"}>
          <Fab
            onClick={onClickRemover}
            className={ 
              quantidade > 0
                ? "remove-botao remove-botao-visivel"
                : "remove-botao remove-botao-oculto"
            }
            data-custom-id="item-remove-button"
            data-custom-description="Remover produto"
            data-custom-info={JSON.stringify({
              productId: props.produto.id,
              productName: props.produto.name,
              composition: props.produto.compositions
            })}
          >
            <Icon>remove</Icon>
          </Fab>
          <Fab 
            onClick={onClickAdd} 
            className={"add-botao"}
            data-custom-id="item-add-button"
            data-custom-description="Adicionar produto"
            data-custom-info={JSON.stringify({
              productId: props.produto.id,
              productName: props.produto.name,
              composition: props.produto.compositions
            })}
          >
            <Icon>add</Icon>
          </Fab>
        </div>
      </div>
    </LazyLoad>
  );
});
