import React, { createContext, useContext, useState, useCallback, useEffect, FC, useRef } from 'react'
import { useKiosk } from '../kiosk/KioskContext';
import { useAuth } from '../auth/AuthContext';
import { useUi } from '../ui/UIContext';
import { useHistory } from 'react-router-dom';
import { SessionService } from '../../../domains/admin/services/SessionService';
import LogService from '../../../Infrastructure/repositories/localLog/LogService';
export interface ISessionContext {
    sessionIsOpen: boolean;
    closeSession: () => Promise<void>
    openSession: () => Promise<void>
    verifySessionIsOpen: () => Promise<boolean>
    sessaoIdPos: string | undefined
}

const SessionContext = createContext<ISessionContext>({} as ISessionContext)
//container
const sessionService = SessionService();

export const SessionProvider: FC = ({ children }) => {

    const { isAuth } = useAuth();
    const { toast } = useUi()
    const { kiosk } = useKiosk();
    const history = useHistory()


    const [sessionIsOpen, setsessionIsOpen] = useState(false);
    const [sessaoIdPos, setSessaoIdPos] = useState<string>()

    const getSession = useCallback(
        async () => {
            try {
                if (kiosk && isAuth) {
                    const response = await sessionService.getSession(kiosk.localId);
                    setsessionIsOpen(response.possuiSessaoAberta);
                    setSessaoIdPos(response.sessaoPOSId);
                    return (response)
                }
            } catch (error) {
                LogService().add({ tag: "CATCH", message: "falha ao obter sessao status" })
            }
        },
        [isAuth, kiosk],
    )

    const verifySessionIsOpen = useCallback(
        async () => {
            try {
                const sessaoAberta = await getSession()
                if (!sessaoAberta?.possuiSessaoAberta) {
                    toast("Totem fechado para consumo", "error")
                    history.replace("/")
                    return false;
                } else {
                    return true;
                }
            } catch (error) {
                const errorMessage = (error as any)?.response?.data?.message ?? undefined;
                toast(errorMessage ?? (error as any)?.message ?? "Falha ao obter sessão", "error");
                return false;
            }
        },
        [getSession, history, toast],
    )

    const closeSession = useCallback(
        async () => {
            if (kiosk && isAuth) {

                try {
                    const response = await sessionService.closeSession(kiosk.localId);
                    toast("o caixa fechado com sucesso", "success");
                    console.log(response);
                    getSession()
                }
                catch (error) {
                    const errorMessage = (error as any)?.response?.data?.message ?? undefined;
                    toast(errorMessage ?? (error as any)?.message ?? "Falha ao fechar sessão", "error");
                }
            }
        },
        [getSession, isAuth, kiosk, toast],
    )
    const openSession = useCallback(
        async () => {
            if (kiosk && isAuth) {
                try {

                    const response = await sessionService.openSession(kiosk.localId);
                    toast("o caixa fechado com sucesso", "success");
                    getSession()
                } catch (error) {
                    const errorMessage = (error as any)?.response?.data?.message ?? undefined;
                    toast(errorMessage ?? (error as any)?.message ?? "Falha ao abrir caixa", "error");
                }
            }
        },
        [getSession, isAuth, kiosk, toast],
    )




    useEffect(() => {
        getSession()
        return () => {

        }
    }, [getSession])


    return (
        <SessionContext.Provider value={{ sessionIsOpen, closeSession, openSession, verifySessionIsOpen, sessaoIdPos }}>
            {children}
        </SessionContext.Provider>
    )
}


export const useSession = () => {
    const context = useContext(SessionContext)
    return context
}