import React, { FC, useState, memo, useRef } from "react";
import { Fab, Icon } from "@material-ui/core";
import "./ProdutoDestaque.css";
import { useStore } from "../../../StoreContext";
import { Product } from "../../../../../../../domains/catalog/aggregates/Product";
import { useCartStore } from "modules/cart/infra/store/CartStore";
import { ICartContext } from "modules/cart/domain/interfaces/IUseCaseCart";

interface ProdutoDestaqueProps {
  produto: Product;
  tipo?: number;
}

export const ProdutoDestaque: FC<ProdutoDestaqueProps> = memo((props) => {
  const [quantidade, setQuantidade] = useState(0);
  // const { addProductCart, removeProductCart, addProductCartVerificarMaioridade } = useCart();
  const [addProductCart, removeProductCart] = useCartStore((state: ICartContext) => [
    state.addProductCart,
    state.removeCartItemByProductId
  ]);
  const { openDetalhamento } = useStore();
  const refContainer = useRef(null);
  const onClickAdd = () => {
    if (props.produto.compositions?.length) {
      openDetalhamento(props.produto, refContainer);
    } else {

        addProductCart(props.produto, 1, [], props.produto.adultHood);
    }
  };

  const onClickRemover = () => {
    removeProductCart(props.produto.id, 1);
  };

  const abrirDetalhamento = () => {
    openDetalhamento(props.produto, refContainer);
  };

  return (
    <div
      ref={refContainer}
      className={"produto-destaque"}
      style={{ backgroundImage: "url(" + props.produto.thumbnailUrl + ")" }}
      // onClick={() => abrirDetalhamento()}
    >
      {/* {
                !props.tipo &&
                <div className={"image"}
                    onClick={onClickAdd}
                >
                </div>
            } */}
      <div
        className={
          quantidade > 0
            ? "quantidade quantidade-visivel"
            : "quantidade quantidade-oculto"
        }
      >
        {quantidade}
      </div>
      {props.produto?.discount && (
        <div className="produto-destaque-desconto">
          -{props.produto.discount}%
        </div>
      )}
      <div 
          className="produto-destaque-click-div"  
          onClick={() => abrirDetalhamento()} 
          data-custom-id="detach-image-button"
          data-custom-description="Abrir detalhamento"
          data-custom-info={JSON.stringify({
            productId: props.produto.id,
            productName: props.produto.name
          })}
        ></div>
      <div onClick={abrirDetalhamento} className={"info"}>
        <div className={"name"}>{props.produto.name}</div>
        <div className={"description"}>{props.produto.description}</div>
        <div className={"value"}>
          <span 
            style={{
              textDecoration: props.produto.discount ? 'line-through' : undefined,
              fontSize: props.produto.discount ? '16px' : undefined,
              color: props.produto.discount ? 'lightgray' : undefined,
            }}
          >
            R$ {props.produto.price.toFixed(2).replace(".", ",")}
          </span>
          {props.produto.discount && (
            <span>
              &nbsp;R$ {(props.produto.price - (props.produto.price * (props.produto.discount / 100))).toFixed(2).replace(".", ",")}
            </span>
          )}
        </div>
      </div>
      <div className={"botoes"}>
        <Fab
          onClick={onClickRemover}
          className={
            quantidade > 0
              ? "remove-botao remove-botao-visivel"
              : "remove-botao remove-botao-oculto"
          }
          data-custom-id="detach-remove-button"
          data-custom-description="Remover produto"
          data-custom-info={JSON.stringify({
            productId: props.produto.id,
            productName: props.produto.name
          })}
        >
          <Icon>remove</Icon>
        </Fab>
        <Fab 
            onClick={onClickAdd} 
            className={"add-botao"}
            data-custom-id="detach-add-button"
            data-custom-description="Adicionar produto"
            data-custom-info={JSON.stringify({
              productId: props.produto.id,
              productName: props.produto.name
            })}
          >
          <Icon>add</Icon>
        </Fab>
      </div>
    </div>
  );
});
