import React, { memo, useState, useMemo, useCallback, useEffect } from "react";
import { Store } from "../../../../domains/catalog/aggregates/Store";
import { ItemStore } from "./Components/itemStore/ItemStore";
import "./StoresListPage.css";
import { Toolbar } from "../../components/toolbar/Toolbar";
import { useRouteMatch, useHistory } from "react-router-dom";
import { ListaCategorias } from "../listaCategorias/ListaCategorias";
import { useCatalog } from "../../../../application/contexts/catalog/CatalogContext";
import { v4 } from "uuid";
import { useUi } from "../../../../application/contexts/ui/UIContext";
import { useWeighingMachine } from "application/contexts/weighingMachine/WeighingMachine";
// const bg = require('../../../../assets/images/igreja/bg.jpg')

export const StoresListPage = memo(() => {
  const [filtroStore, setfiltroStore] = useState<string>("");
  const { path } = useRouteMatch();
  const { catalog } = useCatalog();
  const history = useHistory();

  const [stores, setstores] = useState<Store[]>();
  const { weighingMachineEnable } = useWeighingMachine();
  const { transitionPageAnimation } = useUi();

  const getStore = useCallback(() => {
    console.log("Obter Produtos");
    if (catalog?.stores) {
      console.log("STORES", catalog.stores)
      setstores(catalog?.stores);
    }
  }, [catalog])

  const obterStores = useCallback(() => {
    getStore();
    return () => { };
  }, [catalog, history, path, stores]);

  useEffect(() => {
    obterStores();
  }, [obterStores]);

  useEffect(() => {
    if (catalog) {
      console.log("CATALOG FILTAR", catalog)
      setfiltroStore("all");
      // if (catalog.tags?.length <= 1) {
      //   setfiltroStore("all");
      // }
    }
  }, [catalog]);

  const onClickStore = useCallback(
    (index: number) => {
      transitionPageAnimation(() => {
        history.push(path + "/store/" + index);
      })
    },
    [history, path]
  );

  const limparFiltro = () => {
    setfiltroStore("");
  };


  const onPressVoltarHandle = useCallback(
    () => {

      if (weighingMachineEnable) {
        history.replace('/weighing')
      } else {
        history.replace(`/`);
      }
    },
    [weighingMachineEnable],
  )


  useEffect(() => {
    console.log("changeSTORES", stores)
    return () => {

    }
  }, [stores])

  return (
    <div className="container-store">
      <Toolbar
        imageUrl={catalog?.imageUrl}
        // imageUrl={"/images/igreja/bg.jpg"}
        title={catalog?.name}
        // onPressVoltar={
        //   filtroStore && catalog && catalog.tags.length > 1
        //     ? limparFiltro
        //     : onPressVoltarHandle
        // }
      // imageUrl={
      //   "https://d1e676vce0x2at.cloudfront.net/uploads/post/image/10958/main_destaque.jpg"
      // }
      ></Toolbar>
      {!filtroStore && catalog ? (
        <>
          <ListaCategorias
            catalog={catalog}
            onClickCategoria={(id) => {
              setfiltroStore(id);
            }}
            onClickTodos={() => setfiltroStore("all")}
          />
        </>
      ) :
        (
          <>
            {/* <div className="text-item-store">ESCOLHA UMA OPÇÃO</div> */}
            <div className="container-item-store">
            {stores?.sort((a, b) => a.order - b.order).filter(
                (store) =>
                  store.tags.findIndex((tag) => tag.id === filtroStore) !== -1 || filtroStore === "all"
              ).map((store, index) => (
                <>
                  {/* <div> {store.name}</div> */}
                  <ItemStore
                    key={store.id}
                    nome={store.name}
                    imagemUrl={store.thumbnailUrl}
                    id={store.id}
                    onClick={() => onClickStore(index)} />
                </>
              ))}
            </div>
          </>
        )}
    </div>
  );
});
