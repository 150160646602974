import React, { FC, memo } from "react";
import { ProdutoItem } from "./produtoItem/ProdutoItem";
import { ProdutoDestaque } from "./produtoDestaque/ProdutoDestaque";
import "./ProdutoLista.css";
import { Product } from "../../../../../../domains/catalog/aggregates/Product";

interface ProdutoListaProps {
  produtos: Product[];
  destaque?: boolean;
  hideZeroPrices?: boolean;
}

export const ProdutoLista: FC<ProdutoListaProps> = memo((props) => {

  return (
    <div className="produto-lista" >
      <div className={!props.destaque ? "lista-vertical" : "lista-horizontal"}>
        {props.produtos.filter(item => item.hideCatalog !== true).map((produto, index) => (
          <div key={index} className="listaFadeWithouTransition">
            {props.destaque ? (
              <ProdutoDestaque produto={produto} />
            ) : (
                <ProdutoItem hidePrice={props.hideZeroPrices} produto={produto} />
              )}
          </div>
        ))}
      </div>
    </div>
  );
});
